/* eslint-disable sort-keys */

export const reactionEmojis = ['😀', '😃', '😮', '😁', '😆', '🤣', '😊', '😇', '💬', '💭', '🍻', '🎂', '😢', '👍', '👎', '😬', '😱', '😎', '💪', '🙏', '👏', '👋', '❓', '❗', '✅', '😍', '❤️']

export const mainEmojis: Record<string, string> = {
  ':)': '😃',
  '=)': '😃',
  '))': '😃',
  ':D': '😆',
  ';)': '😉',
  ':(': '😞',
  ':P': '😜',
  ':O': '😮',
  ':0': '😮',
  ':\'(': '😢',
  ':,(': '😢',
  ':cry:': '😢',
  '<3': '❤️',
  ':+1:': '👍',
  ':-1:': '👎'
}

export const namedEmojis: Record<string, string> = {
  blush: '😊',
  relaxed: '☺',
  smirk: '😏',
  heart_eyes: '😍',
  kissing_heart: '😘',
  flushed: '😳',
  relieved: '😥',
  satisfied: '😌',
  grin: '😁',
  grinning: '😀',
  kissing: '😗',
  stuck_out_tongue: '😛',
  sleeping: '😴',
  worried: '😟',
  frowning: '😦',
  anguished: '😧',
  grimacing: '😬',
  confused: '😕',
  hushed: '😯',
  smile: '😃',
  laughing: '😆',
  wink: '😉',
  disappointed: '😞',
  wink2: '😜',
  open_mouth: '😮',
  thumbsup: '👍',
  thumbsdown: '👎',
  expressionless: '😑',
  unamused: '😒',
  sweat_smile: '😅',
  sweat: '😓',
  weary: '😩',
  pensive: '😔',
  confounded: '😖',
  fearful: '😨',
  cold_sweat: '😰',
  persevere: '😣',
  sob: '😭',
  joy: '😂',
  astonished: '😲',
  scream: '😱',
  tired: '😫',
  angry: '😠',
  rage: '😡',
  triumph: '😤',
  sleepy: '😪',
  yum: '😋',
  mask: '😷',
  sunglasses: '😎',
  dizzy: '😵',
  imp: '👿',
  smiling_imp: '😈',
  neutral_face: '😐',
  no_mouth: '😶',
  innocent: '😇',
  alien: '👽',
  heart: '❤️',
  star: '⭐️',
  boom: '💥',
  exclamation: '❗',
  question: '❓',
  musical_note: '🎵',
  fire: '🔥',
  ok_hand: '👌',
  punch: '👊',
  fist: '✊',
  v: '✌',
  wave: '👋',
  hand: '✋',
  open_hands: '👐',
  point_up: '👆',
  point_down: '👇',
  point_left: '👈',
  point_right: '👉',
  raised_hands: '🙌',
  pray: '🙏',
  clap: '👏',
  muscle: '💪',

  couple: '👫',
  boy: '👦',
  girl: '👧',

  see_no_evil: '🙈',
  hear_no_evil: '🙉',
  speak_no_evil: '🙊',
  ear: '👂',
  eyes: '👀',
  tongue: '👅',
  speech_balloon: '💬',
  thought_balloon: '💭',
  snowman: '⛄',
  zap: '⚡',

  cat: '🐱',
  dog: '🐶',
  mouse: '🐭',
  hamster: '🐹',
  rabbit: '🐰',
  monkey: '🐒',
  fish: '🐠',
  cow: '🐄',

  rose: '🌹',
  globe: '🌐',
  sun: '🌞',
  mooon_with_face: '🌛',
  santa: '🎅',
  alarm_clock: '⏰',
  apple: '🍎',
  beer: '🍻',
  birthday: '🎂',
  book: '📚',
  bulb: '💡',
  cake: '🍰',
  calendar: '📆',
  clipboard: '📋',
  closed_book: '📕',
  coffee: '☕',
  computer: '💻',
  confetti_ball: '🎊',
  cookie: '🍪',
  dart: '🎯',
  date: '📅',
  egg: '🍳',
  eggplant: '🍆',
  electric_plug: '🔌',
  email: '✉',
  envelope: '📨',
  euro: '💶',
  eyeglasses: '👓',
  fax: '📠',
  file_folder: '📁',
  fish_cake: '🍥',
  fishing: '🎣',
  flashlight: '🔦',
  floppy_disk: '💾',
  flower_playing_cards: '🎴',
  football: '🏈',
  fork_and_knife: '🍴',
  fried_shrimp: '🍤',
  fries: '🍟',
  game_die: '🎲',
  gem: '💎',
  gift: '🎁',
  hourglass: '⏳',
  key: '🔑',
  lock: '🔒',
  memo: '📝',
  paperclip: '📎',
  phone: '☎',
  pill: '💊',
  pineapple: '🍍',
  pizza: '🍕',
  pushpin: '📌',
  tada: '🎉',
  tea: '🍵',
  toilet: '🚽',
  trophy: '🏆',
  watch: '⌚',
  wrench: '🔧',
  airplane: '✈',
  car: '🚗',
  checkered_flag: '🏁',
  construction: '🚧',
  house: '🏠',
  automobile: '🚘',
  rainbow: '🌈',
  rocket: '🚀',
  rotating_light: '🚨',
  tractor: '🚜',
  warning: '⚠',
  poop: '💩'
}

export const emojify = (text: string) => {
  const arr = text.split(' ')
  const lastWord = arr[arr.length - 1]

  if (mainEmojis[lastWord]) {
    arr.pop()
    arr.push(mainEmojis[lastWord] + ' ')
    text = arr.join(' ')
  } else if (lastWord.startsWith(':') && lastWord.endsWith(':')) {
    const emoji = lastWord.split(':')[1]
    if (namedEmojis[emoji]) {
      arr.pop()
      arr.push(namedEmojis[emoji] + ' ')
      text = arr.join(' ')
    }
  }

  return text
}

export const emojifyText = (text: string) => {
  const arr = text.split(' ')
  const lastWord = arr[arr.length - 1]

  if (mainEmojis[lastWord]) {
    arr.pop()
    arr.push(mainEmojis[lastWord])
    text = arr.join(' ')
  } else if (lastWord.startsWith(':') && lastWord.endsWith(':')) {
    const emoji = lastWord.split(':')[1]
    if (namedEmojis[emoji]) {
      arr.pop()
      arr.push(namedEmojis[emoji])
      text = arr.join(' ')
    }
  }

  return text
}

export const autocomplete = (text: string) => {
  const match: string[] = []
  const arr = text.split(' ')
  const lastWord = arr[arr.length - 1]
  const emoji = lastWord.split(':')[1]

  if (lastWord.startsWith(':')) {
    Object.keys(namedEmojis).forEach(e => {
      if (e.startsWith(emoji)) match.push(namedEmojis[e])
    })
  }

  return match
}

/**
 * Used by emojiAutocomplete. In chat we are using emojify function
 * @todo - check if we can use this also instead of emojify
 */
export const emojifyInline = (text: string, caretPos: number) => {
  const textBeforeCaret = text.slice(0, caretPos)
  const textAfterCaret = text.slice(caretPos)

  const wordsBeforeCaret = textBeforeCaret.split(' ')
  const lastWord = wordsBeforeCaret[wordsBeforeCaret.length - 1]

  if (mainEmojis[lastWord]) {
    wordsBeforeCaret[wordsBeforeCaret.length - 1] = mainEmojis[lastWord] + ' '
  } else if (lastWord.startsWith(':') && lastWord.endsWith(':')) {
    const emoji = lastWord.split(':')[1]
    if (namedEmojis[emoji]) {
      wordsBeforeCaret[wordsBeforeCaret.length - 1] = namedEmojis[emoji] + ' '
    }
  }

  return wordsBeforeCaret.join(' ') + textAfterCaret
}

/**
 * Action for adding emojis to input. Just add use:emojiAutocomplete to input where you want to allow adding emojis
 */
export function emojiAutocomplete (node: HTMLInputElement | HTMLTextAreaElement) {
  const handleKeyUp = (e: Event) => {
    const text = (e.target as HTMLInputElement | HTMLTextAreaElement).value
    const originalCaretPos = node.selectionStart || text.length
    const newValue = emojifyInline(text, originalCaretPos)
    if (newValue === text) return
    node.value = newValue

    const newCaretPos = node.value.length - text.length + originalCaretPos
    node.setSelectionRange(newCaretPos, newCaretPos)
    node.dispatchEvent(new Event('input')) // to handle case when last added emoji not working
  }
  node.addEventListener('keyup', handleKeyUp)

  return {
    destroy () {
      node.removeEventListener('keyup', handleKeyUp)
    }
  }
}
